* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --theme_color: #00b4db;
  --theme_shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.theme_color {
  color: #00b4db;
}
.theme_bg_color {
  background-color: #00b4db !important;
}
.w_90 {
  width: 90%;
}
.w_85 {
  width: 85%;
}
.w_87 {
  width: 87%;
}
a:hover {
  color: #00b4db;
  text-decoration: none;
}

.btn:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid transparent;
}

label:active {
  outline: none;
  box-shadow: none;
}
.table_div {
  overflow: auto;
}
button {
  border: none;
}
table {
  text-align: center;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 #ffffff;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.dropdown {
  outline: none;
}
.dropdown-menu {
  padding: 0;
}
svg {
  cursor: pointer;
}
ul {
  list-style-type: none;
}
textarea:focus {
  outline: none;
}

h1 {
  font-size: clamp(1.5rem, 2.3vw, 2.3rem);
}
h4 {
  font-size: clamp(1.3rem, 2.3vw, 1.8rem);
}
p {
  font-size: clamp(0.6rem, 1.7vw, 0.8rem);
  margin: 0;
}
@media screen and (max-width: 780px) {
  .w_90 {
    width: 100%;
  }
  .w_85 {
    width: 100%;
  }
  .w_87 {
    width: 100%;
  }
}
