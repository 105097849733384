.login_page {
  height: 100vh;
}

.navbar.login {
  background-color: var(--theme_color);
  height: 80px;
  position: static !important;
  top: 0;
  width: 100%;
  /* z-index: 100; */
}
.navbar.login {
  display: block;
}
.navbar.login img {
  margin-top: 4vh;
}
.login_page_form {
  background-color: #fff;
  height: auto;
  min-height: 70%;
  margin: auto;
  width: 80%;
  padding: 40px 0;
}
.login_bottom {
  margin-top: 4vh;
}
.login_bottom h4 {
  color: var(--theme_color);
  cursor: pointer;
  font-size: clamp(1rem, 3vw, 1.3rem);
}
.login_page_form a {
  color: var(--theme_color);
}

.login_page_form .btn {
  background-color: var(--theme_color);
}
.login_form {
  width: 100%;
}
.login_h1 {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  font-weight: 900;
}

.login_p {
  font-size: clamp(0.8rem, 1.9vw, 1rem);
  font-weight: 600;
  margin-top: 3vh;
}
.login_page .text-danger {
  height: 3lpx;
  font-weight: 700;
}
/* .login_page_top { */
/* margin-bottom: 1rem; */
/* } */
.btn_signin {
  font-weight: 700;
  letter-spacing: 1.3px;
  font-size: 0.9rem;
}

.login__with__otp__input-container {
  text-align: center;
  display: flex;
  justify-content: center;
}
.login__with__otp__input-container > input {
  width: 60px;
  height: 60px;
  padding: 10px;
  border: 2px solid var(--theme_color);
  border-radius: 5px;
  background: white;
  outline-width: 0px;
  font-size: 20px;
  margin: auto 2px;
  text-align: center;
}

@media screen and (min-width: 500px) {
  .login_page_form {
    max-width: 600px;
  }

  .btn_signin {
    padding: 10px 45px;
    border-radius: 20px;
  }
  .login_page {
    background-image: linear-gradient(160deg, #00b4db 50%, transparent 50%);
  }
  .login_page_form {
    background-color: #fff;
    height: auto;
    min-height: 60%;
    margin: 60px auto auto auto;
    width: 70%;
    padding: 3vh 4vw;
    border-radius: 3px;
    box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 49%);
    -webkit-box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 49%);
    -moz-box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.49);
  }
}
@media screen and (min-width: 780px) {
  .login_page_form {
    width: 50%;
  }
}
@media screen and (max-width: 780px) {
  .login_page_top {
    margin-bottom: 1rem;
  }
}
