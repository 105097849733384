.upload_project {
  display: inline;
  border: 1px solid var(--theme_color);
  padding: 10px;
  border-radius: 20px;
  color: var(--theme_color);
  font-weight: 900;
  margin-left: 3rem;
  cursor: pointer;
}
.progress_next_icon {
  font-size: 3.5rem;
  color: #03b2cb;
}
.cover_img {
  margin-top: 0.5rem;
  cursor: pointer;
  color: #fff;
  font-size: clamp(0.7rem, 2vw, 1rem);
  border: 2px solid var(--theme_color);
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 10px;
  display: table;
  color: rgb(0, 0, 0);
  cursor: pointer;
}
.project_upload .tk_dropdown svg {
  position: absolute;
  right: 3%;
  top: 33%;
}
.project_upload .tk_dropdown .btn {
  width: 300px;
  position: relative;
  height: 35px;
}

.project_upload_container .project_card {
  width: 100%;
}

.project_view_container{
  margin: 4vh 5vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
}

.project_view_img_container img{
  width: 80%;
  border-radius: 10px;
}
.project_view_info{
  width: 120%;
}
.project_view_category{
 background-color: #e5e5e5;
 border-radius: 50px;
 padding: 0.5rem;
 margin-top: 2rem;
 font-size: 0.8rem;
}
.project_view_info h1{
margin: 1rem 0;
}
.project_view_category_label{
  margin-bottom: 0.5rem;
}
.project_view_description{
  margin: 1.5rem 0;
}
.project_view_info span{
  margin: 0.5rem 0;
}
.project_link_btn{
  background-color: #60ba1d;
margin-top: 5vh;
}