.ytp-endscreen-content {
  display: none !important;
}
.video_card {
  margin: 10px 20px;
  position: relative;
}

.card {
  border: none;
}
.null {
  display: none;
}
.locked_video {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
  width: 301px;
  z-index: 1;
  background-color: rgb(219, 219, 219);
}
