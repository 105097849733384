@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400;700&family=Poppins:wght@700&display=swap');
/* @font-face {
  font-family: 'Exo';
  src:url('https://fonts.googleapis.com/css2?family=Exo&family=Poppins:wght@700&display=swap');
} */


.modal {
  display: none;
  position: fixed;
  z-index: 100;
  padding: 15% 0 0 250px;
  width: 100%;
  overflow: auto;
  background-color: rgba(49, 49, 49, 0.349);
}
.modal.badge_modal {
  padding: 10px 0 0 250px;
}
.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  width: auto;
  font-weight: normal!important;
  box-shadow: 0 1px 5px 1px rgb(50 50 93 / 25%), 0 1px 3px -1px rgb(0 0 0 / 30%);
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0 1px 3px -1px;
  -webkit-box-shadow: rgb(50 50 93 / 25%) 0 1px 5px 1px,
    rgb(0 0 0 / 30%) 0 1px 3px -1px;
}

.badge_modal .modal_content {
  padding: 10px;
  background-color: #fff;
  height: auto;
}

.badge_modal .modal_content img {
  width: 100%;
}
.badge_modal .modal_content svg {
  position: relative;
  top: 40px;
  left: 20px;
}
.show_modal {
  display: block;
}
.modal.certificate_modal {
  padding: 10px 0 0 250px;
}
.modal.certificate_modal {
  padding: 10px 0 0 250px;
}

.certificate_modal .modal_content {
  width: fit-content;
  height: 95vh;
  position: relative;
}
.certificate_modal_container {
  height: auto;
}
.certificate_modal_container {
  transform: scale(0.8);
  transform-origin: bottom;
  margin-bottom: 0;
  /* margin-top: 6rem; */
}

.certificate_page {
  background-image: url('../../../Assets/Profile/Certificate/Bg.png');
  margin: 0rem auto;
  display: block;
  width: 596px;
  height: 842px;
  padding: 16rem 2rem 1rem;
  font-family: 'Exo', sans-serif;
}
.certificate_page img {
  background-color: transparent;
  width: auto;
}
.certificate_batch {
  width: fit-content !important;
}
.certificate_title {
  text-align: center;
}
.certificate_title p {
  font-size: 0.8rem;
  margin-top: 8px;
  font-family: 'Exo', sans-serif;
}

.certificate_name {
  text-align: center;

}

.certificate_name h1 {
  margin: 2rem 0;
  color: #0c83b0;
  font-weight: 900;
  font-family: 'Poppins', sans-serif;
}
.certificate_name p {
  font-size: 1rem;
  font-family: 'Exo', sans-serif;

}
.certificate_signature {
  text-align: center;
  width: 12rem;
  margin-top: 1rem;

}
.certificate_signature img {
  width: 50%;
}
.certificate_signature h5 {
  font-size: 1rem;
  font-weight: 900;
  margin-bottom: 0;
  border-top: 2px solid #0c83b0;
  padding-top: 10px;
  font-family: 'Exo', sans-serif;

}
.certificate_signature p {
  margin-top: 0;
  font-size: 0.8rem;
  font-family: 'Exo', sans-serif;

}
.certificate_alumni_container {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 0;
}
.certificate_alumni {
  font-size: 0.9rem;
  margin-bottom: 0.6rem;
  font-family: 'Exo', sans-serif;

}

.certificate_alumni_list li {
  list-style-type: none;
  margin-top: 0rem;
  display: inline;
  margin: 8px;
  font-size: 0.8rem;
  font-weight: 900;
  font-family: 'Exo', sans-serif;

}

.certificate_social {
  width: 4rem !important;
  margin: 0 !important;
}

.certificate_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
}
.modal_content .certificate_footer {
  margin-top: 3.6rem;
}

.certificate_footer span {
  font-size: 0.8rem;
}
.download_btn {
  position: absolute;
  bottom: 25px;
}


  


@media screen and (min-width: 560px) and (max-width: 860px) {
  .modal.badge_modal {
    padding: 20px 0 0 0;
  }
  .certificate_modal .modal_content {
    height: 97vh
  }
  .download_btn {
    
    position: absolute;
    bottom:6px;
  }
}
@media screen and (max-width: 780px) {
  .modal {
    padding-left: 0;
    padding: 30vw 0 10px;
  }
  .modal.badge_modal {
    padding: 20px 0 0 0;
  }
  .modal.certificate_modal {
    padding: 10px 10px;
  }
  .certificate_modal .modal_content {
    width: fit-content;
  }
  
}
@media (min-width: 768px) and (max-width: 991.98px) { 
  .certificate_modal .modal_content {
    height: 85vh
  }
  .download_btn {
    
    position: absolute;
    bottom:25px;
  }
}
@media screen and (max-width: 501px) {
  .badge_modal .modal_content img {
    width: 300px;
  }
  .modal {
    padding: 50vw 0 10px;
  }
  .modal.badge_modal {
    padding: 50vw 0 10px;
  }
  .modal.certificate_modal {
    padding: 20vw 10px;
  }
  .badge_modal .modal_content svg {
    top: 5px;
    left: 10px;
  }
  .certificate_modal_container {
    transform: scale(0.5);
    transform-origin: center;
    margin-bottom: 0;
    /* margin-top: 6rem; */
  }
  .certificate_modal .modal_content {
    width: 100%;
    height: 73vh
  }
  .download_btn {
    
    position: absolute;
    bottom:15px;
  }
}
@media screen and (max-width: 375px) {
  .certificate_modal_container {
    transform: scale(0.45);
    transform-origin: center;
    margin-bottom: 0;
  }

  .certificate_modal .modal_content {
    height: 75vh
  }
  .download_btn {
    
    position: absolute;
    bottom:10px;
  }
  
}