.download_icon {
  width: 1rem;
}

.plan_details {
  background-color: #a5efff;
  padding: 20px;
  border-radius: 20px;
}
.plan_details .date {
  border-bottom: 1px dashed #fff;
}
.plan_details h5 {
  margin-top: 1rem;
  font-size: clamp(1rem, 2vw, 1.2rem);
}

.no_subscription img {
  width: 400px;
}
