.carousel_item {
  min-width: fit-content;
}

.ds_top {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(90px, 100px);
  grid-gap: 5vw;
}
.ds_welcome {
  padding: 0 20px 0px 25px;
  color: #fff;
  border-radius: 20px;

  background: linear-gradient(
    0deg,
    #037fa1 -0.01%,
    #0099c3 34.33%,
    #00add5 73.43%,
    #00b4db 100.01%
  );
}
.ds_welcome.grid {
  grid-column: span 2;
  grid-row: span 2;
}
.ds_welcome.n_grid {
  min-height: 200px;
}
.welcome_img {
  margin-top: -6.5rem;
}
.ds_welcome p {
  font-size: clamp(0.8rem, 1vw, 0.9rem);
}
.upcoming_class {
  grid-column: span 2;
  grid-row: span 2;
}

.react-multi-carousel-list {
  overflow: hidden;
  height: 280px;
  cursor: pointer;
}
.react-multiple-carousel__arrow {
  display: none;
}
.react-multi-carousel-dot--active button {
  background: var(--theme_color) !important;
}

.react-multi-carousel-dot button {
  border-color: var(--theme_color);
}
.profile_info_box {
  border: 1px solid #000;
  border-radius: 20px;
  min-width: 230px;
  max-width: 100%;
  margin-bottom: 60px;
  grid-column: span 1;
  grid-row: span 5;
}
.profile_info_box .profile_pic_cover {
  margin-top: 3rem;
}
.profile_pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid var(--theme_color);
  margin: 30px auto;
  background-size: cover;
  object-fit: cover;
}
.level img {
  max-width: 140px;
}
.roadmap {
  overflow-x: scroll;
  scroll-behavior: smooth;
  margin-top: 50px;
}
.roadmap::-webkit-scrollbar {
  width: 0 !important;
}
.roadmap {
  overflow: -moz-scrollbars-none;
}
.roadmap {
  -ms-overflow-style: none;
}
.roadmap .roadmap_img {
  width: 150%;
  height: auto;
  margin: 0 20px;
}
.rd_nav_icon {
  width: 52px;
  height: 52px;
  cursor: pointer;
}
.cicular_progress {
  width: 90px;
}
.upcoming_item h6 {
  font-size: clamp(0.6rem, 1.5vw, 0.9rem);
}
@media screen and (min-width: 1200px) {
  .profile_info_box {
    width: 330px;
  }
}
@media screen and (max-width: 1200px) {
  .upcoming_class,
  .ds_welcome.grid,
  .profile_info_box {
    grid-column: span 3;
  }
}
@media screen and (max-width: 500px) {
  .hide_on_mobile {
    display: none;
  }
  .roadmap .roadmap_img {
    width: 250%;
  }
  .welcome_img {
    width: 120px;
  }
  .ds_welcome {
    padding: 0 0 20px 25px;
  }
  .grid-section {
    grid-template-columns: repeat(1, 1fr);
  }

  .hero-content p {
    font-size: 1.4rem;
  }
  .rd_nav_icon {
    width: 32px;
    height: 32px;
  }
}
@media screen and (max-width: 350px) {
  .level img {
    max-width: 110px;
  }
  .ds_welcome.grid {
    grid-row: span 3;
  }
}
