/* .card_item .project_info {
  z-index: 5;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 5px 10px 0;
  transition: all 0.2s linear;
  font-size: 0.7rem;
  width: 230px;
}
.project_card {
  width: 260px;
  padding: 0.3rem;
  background-color: #f2f2f2;
  margin: 50px 10px 0 30px;
}

.project_card p {
  font-size: 0.75rem;
  height: 80px;
  overflow: hidden;
  width: 230px;
  
}
.project_name{
  width: 230px;
}

.card_item {
  height: 330px;
  cursor: pointer;
}
.card_image {
  overflow: hidden;
}
.card_item .card_image img {
  width: 100%;
  height: 168px;
  background-size: cover;
  object-fit: cover;
  object-position: 50% 50%;
  cursor: pointer;
  transition: transform 0.5s ease;
} */

.project_card {
  margin: 4vh 3vw;
  width: 30%;
  position: relative;
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 5px 35px -20px black;
  transition: all 0.5s ease-in-out; 
  cursor: pointer;
  max-height: 400px;
}
.project_card:hover{
 width: 32%;
}
.project_image_container {
  margin-bottom: 10px;
}
.project_image_container img {
  width: 100%;
  border-radius: 10px;
}
.project_category {
  font-size: 0.8rem;
  margin-top: 5px;
  color: #00b5db;
  display: flex;
  align-items: center;
  justify-content: start;
}
.project_category span{
  margin-left: 5px;
}
.project_title {
  margin: 10px 0;
  font-weight: 900;
}
.project_btn {
  display: block;
  margin: 0 auto;
  background-color: #60ba1d;
  width: 120px;
  height: 30px;
}
