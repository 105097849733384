.upcoming_item {
  max-width: 280px;
  min-height: 220px;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 15px;
  margin: 0 3px;
}
.upcoming_item p {
  font-size: 20px;
}

/* .timer {
  margin: 0 0vw;
} */

/* .password_modal_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.timer_component {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 2px;
  text-align: center;
}
.timer_container {
  display: flex;
  min-height: 0;
  margin: 0;
  align-items: center;
  justify-content: start;
  width: 100%;
  box-sizing: border-box;
}

.timer_item {
  background-color: #0099c3;
  padding: 0 0.6vw;
  color: #fff;
  font-size: clamp(1rem, 2vw, 1.2rem);
  text-align: center;
  margin: 0 1px;
  min-width: 18px;
}

.time_text {
  font-size: clamp(0.5rem, 1.4vw, 0.8rem);
  color: var(--theme_color);
  font-weight: 900;
}
.modal.password {
  padding: 8% 0 0 250px;
}
.modal.password .error {
  font-size: 0.9rem;
}
@media all and (max-width: 780px) {
  .modal.password {
    padding: 30vw 0 10px;
  }
  .modal.password h5 {
    font-size: 0.9rem;
  }
  .modal.password .error {
    font-size: 0.8rem;
  }
}
.password_modal {
  display: flex;
  flex-direction: column;
  width: auto;
}
.password_modal_form {
  margin-top: 30px;
}
.password_modal input {
  border: 1.9px solid #000000;
  padding: 5px;
}
.password_modal input:focus {
  border: 1.9px solid #000000;
}

@media all and (min-width: 500px) {
  .password_modal {
    width: 300px;
  }
}
@media all and (min-width: 1180px) {
  .password_modal {
    width: 500px;
  }
}

.message_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #2596be;
  border-radius: 30px;
  padding: 3vh 4vw;
  margin: 5rem 0;
}
.message_container p {
  color: #2596be;
  font-size: 1rem;
}
.message_img {
  width: 50%;
}
.message_feedback_btn {
  background-color: #62b936 !important;
  margin-top: 2vh;
  font-weight: 900;
}
.message_paper_blast_img {
  width: 100%;
}

.feedback_container,
.reschedule_container {
  background-color: white;
  box-shadow: 0px 3px 20px -10px black;
  border-radius: 10px;
  padding: 8vh 2vw 3vh;
  position: absolute;
  margin-top: 2rem;
  width: 60%;
  top: 3%;
  left: 75%;
  transform: translate(-75%, -3%);
}
.feedback_close_icon,
.reschedule_close_icon {
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 0.5;
  background-color: #e2e2e2;
  border-radius: 100%;
  padding: 2px;
}
.feedback_check_container p {
  display: inline;
  margin-right: 2vw;
}
.feedback_check_container label {
  margin-right: 5px;
}
.feedback_check_container input {
  margin-right: 2vw;
}
.feedback_done_btn {
  background-color: #62b936 !important;
  margin-top: 2vh;
}
.feedback_rating_container {
  width: 50%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .feedback_rating { */
/* } */
.feedback_rating_title p {
  margin-bottom: 0.8rem;
}
.feedback_suggestion {
  margin-top: 1rem;
}
.feedback_suggestion textarea {
  border-radius: 10px;
  padding: 0.5rem;
}
.feedback_suggestion p {
  margin-bottom: 0.5rem;
}

.reschedule_select_date > p,
.reschedule_select_time > p {
  opacity: 0.5;
  margin-bottom: 0.2vh;
}
.reschedule_select_date {
  margin-top: 1rem;
}

.reschedule_date_container {
  width: fit-content;
  padding: 1rem 3vw;
  background-color: #00b5db;
  display: inline-block;
  color: white;
  font-weight: 900;
  margin: 1vw;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.reschedule_date_container_selected {
  width: fit-content;
  padding: 1rem 3vw;
  background-color: lightgreen;
  display: inline-block;
  color: white;
  font-weight: 900;
  margin: 1vw;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.reschedule_date_container_selected p {
  font-size: 1rem;
  margin-bottom: 0rem;
}

.reschedule_date_container_selected span {
  font-size: 0.8rem;
  font-weight: normal;
}
.reschedule_date_container p {
  font-size: 1rem;
  margin-bottom: 0rem;
}

.reschedule_date_container span {
  font-size: 0.8rem;
  font-weight: normal;
}
.reschedule_select_time {
  margin-top: 1rem;
}

.reschedule_time_container {
  background-color: #eef3f7;
  padding: 0.5rem 0.8rem;
  border-radius: 3px;
  display: inline-block;
  margin: 1vw;
  cursor: pointer;
}

.reschedule_time_container_selected {
  background-color: lightgreen;
  padding: 0.5rem 0.8rem;
  border-radius: 3px;
  display: inline-block;
  margin: 1vw;
  cursor: pointer;
}

.reschedule_submit_btn {
  margin-top: 2rem;
  font-weight: 900;
  background-color: #62b936 !important;
}
