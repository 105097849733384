.my_report button {
  background-color: #0096b8;
  color: #fff;
  padding: 15px;
  border-radius: 30px;
  width: 200px;
  margin: 1rem 0;
}
.report_progress {
  width: 700px;
  margin-left: 1.5vw;
}
.download_icon svg {
  stroke-width: 1;
}
.report_progress_item {
  width: 120px;
}

.report_progress_item span {
  white-space: nowrap;
}
.report_badges img {
  margin-right: 40px;
  margin-bottom: 40px;
}
.my_report h4 {
  font-weight: 600;
  font-size: 1.4rem;
}
.skill_unlocked_container .skill_name {
  background-color: #0096b8;
  padding: 5px 10px;
  border-radius: 20px;
  color:white;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
.skill_unlocked_item {
  margin-top: 20px;
}

.progress_dot {
  background-color: #cecece;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.progress_dot.active {
  background-color: #0096b8;
}
.my_report p {
  font-size: 1rem;
}
@media all and (max-width: 780px) {
  .report_progress {
    width: auto;
    padding: 0 20px;
    margin-top: 20px;
  }
  .report_badges img {
    margin-right: 10px;
  }
  .skill_unlocked_container .col-5 {
    max-width: 100% !important;
    padding: 0;
  }
}
@media all and (max-width: 500px) {
  .report_progress_item span {
    font-size: 0.7rem;
  }
  .report_progress_item {
    width: 70px;
  }
  .my_report button {
    padding: 10px;
    border-radius: 30px;
    font-size: 1rem;
  }
  .my_report h4 {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .progress_dot {
    height: 14px;
    width: 14px;
  }
  .skill_unlocked_container .skill_name {
    padding: 2px 5px;
    font-size: 0.8rem;
  }
  .my_report p {
    font-size: 0.75rem;
  }
}
