.profile_box {
  border: 4px solid var(--theme_color);
  border-radius: 20px;
  padding: 40px 50px;
}

.profile_info {
  margin-left: 100px;
}
.level img {
  max-width: 110px;
}
.progress_next_icon {
  font-size: 3.5rem;
  color: #03b2cb;
}
.progress {
  width: 150px !important;
  height: 10px;
  border-radius: 20px;
}
.progress-bar {
  height: 10px;
  border-radius: 20px;
  background: rgb(25, 25, 25);
  background: linear-gradient(
    87deg,
    rgb(36, 36, 36) 4%,
    rgba(1, 18, 189, 1) 61%
  );
}
.progress_bar span {
  font-size: 0.6rem;
  font-weight: bold;
  margin: 0.3rem 0;
}
.teacher_badge {
  width: 200px;
  height: 200px;
}
.teacher_badge_sm {
  width: 120px;
  height: 120px;
}

.badge_item {
  background-color: #e5e5e5;
  padding: 3px 10px;
  border-radius: 5px;
  margin: 0 20px 10px 0;
}
.badge_item svg {
  float: right;
}
.smbadge_section {
  width: 100%;
}
.profile_edit_input .badge_item {
  padding: 5px 5px 3px 10px;
}
.profile_edit_input .badge_item svg {
  float: right;
}
.profile_text {
  font-size: clamp(0.8rem, 1.7vw, 1rem);
  word-wrap: break-word;
}
.certificate {
  padding: 30px;
  width: 250px;
  margin-right: 1rem;
  margin-top: 1rem;
  background-color: #e5e5e5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.certificate span {
  font-size: 0.8rem;
}
.certificate img {
  width: 150px;
  height: auto;
  background-color: #000;
  background-size: cover;
  object-fit: cover;
  margin: 10px auto 10px auto;
  display: block;
}
.profile_progress {
  width: 350px;
}
.profile_progress span {
  font-size: 0.8rem;
}
.sm_toggle {
  display: none;
}

.profile_edit_input .tk_dropdown .dropdown-menu {
  width: 100%;
}
.profile_edit_input .MuiInputBase-root > input {
  border-radius: 10px;
  background-color: #e5e5e5;
  border: none;
}
.profile_edit_input .MuiGrid-container {
  margin-left: 0;
  width: 100% !important;
  display: flex;
  flex-direction: column;
}

.profile_edit_radio ul {
  list-style: none;
}
.profile_edit_radio li {
  display: inline-block;
}
.profile_edit_radio input {
  visibility: hidden;
}
.profile_edit_radio label {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 3px 10px;
  font-size: 0.9rem;
  text-align: center;
}
.profile_edit_radio input:checked + label {
  color: #fff;
  background: var(--theme_color);
}
.profile_edit_input > .tk_dropdown label {
  font-weight: 900;
}

.profile_edit_input .badge_item {
  padding: 5px 5px 3px 10px;
}
.profile_edit_input .badge_item svg {
  float: right;
}
.profile_edit_input label {
  font-weight: 900;
}
.profile_edit_input .PhoneInput {
  border-radius: 10px;
  background-color: #e5e5e5;
  border: none;
  padding: 0 10px;
  pointer-events: none;
}
.profile_edit .profile_pic {
  width: 200px;
  height: 200px;
  border: none;
}
.MuiInputBase-root > input {
  border: 1.9px solid var(--theme_color);
  border-radius: 20px;
  padding: 6px 10px;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
}

.upload_pic {
  cursor: pointer;
  color: #fff;
  font-size: clamp(0.7rem, 2vw, 1rem);
  padding: 9px 25px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  background-color: rgb(0, 249, 189);
}

.upload_pic label {
  font-weight: 900;
  cursor: pointer;
  margin-bottom: 0;
}
.grade input {
  height: 0px !important;
}
@media all and (min-width: 780px) {
  .profile_edit_radio {
    width: 80%;
  }
}
.profile_edit_radio.grade label {
  border-radius: 0px !important;
  width: 2.3rem;
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile_pic_option {
  width: 60%;
}
@media screen and (max-width: 1090px) {
  .profile_box {
    padding: 30px 30px;
  }
  .smbadge_section {
    justify-content: center;
    flex-direction: column;
  }
  .tk_btn {
    padding: 9px 5px;
  }
  .profile_pic {
    width: 120px;
    height: 120px;
  }
  .lg_toggle {
    display: none;
  }
  .sm_toggle {
    display: block;
  }
  .student_intro span {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 780px) {
  .profile_pic_option {
    width: 80%;
  }
}
@media screen and (max-width: 500px) {
  .profile_pic {
    width: 90px;
    height: 90px;
  }
  .profile_pic_cover {
    width: 100px;
  }
  .profile_info {
    margin-left: 5px;
  }
  .profile_info h1 {
    font-size: 0.9rem;
  }
  .profile_info span {
    font-size: 0.7rem;
  }
  .progress {
    width: 100px !important;
  }
  .profile_box {
    padding: 30px 0px;
  }
  .teacher_badge_sm {
    width: 90px;
    height: 90px;
  }
  .profile_pic_option {
    width: 70%;
  }
}
