.trial_taken_view svg {
  cursor: pointer;
}

.tk_btn {
  color: #fff;
  font-size: clamp(0.7rem, 2vw, 1rem);
  padding: 9px 25px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tk_btn.sm {
  padding: 3px 5px;
}

.tk_btn span {
  margin: 0 10px;
}

.tk_dropdown .dropdown button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1.0498px solid #cccccc;
}
.tk_dropdown svg {
  margin-left: 20px;
}
.tk_dropdown .dropdown-toggle::after {
  content: none;
}
.tk_dropdown .dropdown-menu {
  border: 1px dashed rgb(158, 158, 158);
  transform: none !important;
}
.tk_dropdown .dropdown-menu .dropdown-item {
  border: 1px dashed rgb(185, 185, 185);
}

.tk_dropdown .dropdown-menu .dropdown-item:hover {
  cursor: pointer;
  color: #fff;
  background-color: var(--theme_color);
}

.info_box,
.shadow_bg {
  box-shadow: var(--theme_shadow);
  -moz-box-shadow: var(--theme_shadow);
  -webkit-box-shadow: var(--theme_shadow);
}
.trial_info_box {
  padding: 19px 20px;
}
.trial_info {
  font-size: clamp(0.7rem, 2vw, 1rem);
  font-weight: 900;
}
.expand_text {
  padding: 10px 20px 20px 12px;
  text-align: start;
  font-weight: 500;
  font-size: clamp(0.5rem, 1.8vw, 1rem);
}

.month_title {
  font-size: clamp(1.3rem, 2vw, 2rem);
}

.back_title {
  margin-left: 50px;
  font-weight: 900;
  font-size: clamp(1.7rem, 2.4vw, 2rem);
}
.profile_pic_cover {
  position: relative;
}
.circle2 {
  position: absolute;
  top: 0;
  z-index: -1;
  right: 0;
}
.circle3 {
  position: absolute;
  bottom: 15%;
  left: 0;
}
.circle4 {
  position: absolute;
  top: -20px;
  right: 0;
}
.circle5 {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: -20px;
}
.circle6 {
  position: absolute;
}

.MuiPickersToolbar-toolbar {
  background-color: var(--theme_color) !important;
}

.MuiInput-underline:before {
  content: "" !important;
  display: none;
}
.info_box_container,
.shadow_bg {
  padding: 19px 20px;
  font-size: clamp(0.7rem, 2vw, 1rem);
  font-weight: 900;
}

.tk_section {
  border: 3px solid var(--theme_color);
  padding: 20px 40px;
  border-radius: 20px;
  margin: 60px 0 60px 0;
}
.tk_section span {
  cursor: pointer;
}

.tk_section .heading {
  font-size: clamp(1rem, 1.4vw, 2.2rem);
}
.tk_section .desc {
  font-size: clamp(1rem, 1.3vw, 2rem);
}
.tk_section .title {
  font-size: clamp(1rem, 1.2vw, 1.5rem);
}
.tk_section img {
  height: 200px;
}
.counter_container {
  border: 2px solid var(--theme_color);
  padding: 10px;
  border-radius: 15px;
}
.modal_content {
  border-radius: 10px;
}
.text-error {
  color: red;
}
.modal_content.success_modal {
  width: 50%;
  position: relative;
  box-shadow: -2px 2px 23px -4px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: -2px 2px 23px -4px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: -2px 2px 23px -4px rgba(0, 0, 0, 0.55);
}
@media all and (min-width: 780px) {
  .modal.full_view {
    padding: 15% 0 0 0;
  }
  .modal.full_view .success_modal {
    width: 40% !important;
  }
}
.modal_content.success_modal .success_msg {
  color: #353535;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  font-weight: 900;
}
.modal_content.success_modal .sucess_tick_img {
  width: 90px;
}
.modal_content.success_modal .success_clr {
  color: rgb(98, 207, 76);
}
.modal_content.success_modal .error_clr {
  color: rgb(197, 61, 61);
}
.modal_content.success_modal .msg_text {
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.success_modal .continue {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 10px;
  color: #fff;
  border-radius: 4px;
  box-shadow: 1px 7px 20px -3px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 1px 7px 20px -3px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 1px 7px 20px -3px rgba(0, 0, 0, 0.55);
}
.success_btn_clr {
  background-color: rgb(98, 207, 76);
}
.error_btn_clr {
  background-color: rgb(255, 27, 27);
}
@media screen and (max-width: 780px) {
  .info_box_container,
  .shadow_bg {
    padding: 15px 10px;
  }
  .info_box_status {
    padding: 5px 15px;
  }
  .info_box_status img {
    width: 32px;
  }
}

@media screen and (min-width: 780px) and (max-width: 900px) {
  .tk_section img {
    height: 140px;
  }
}
@media screen and (max-width: 500px) {
  .info_box_status {
    padding: 5px 10px;
  }
  .info_box_status img {
    width: 22px;
  }

  .tk_section {
    padding: 20px 10px;
  }
  .tk_section img {
    height: 100px;
  }
  .tk_section .tower {
    height: 150px;
  }
  .modal_content.success_modal {
    width: 90%;
  }
}


