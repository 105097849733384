.box-shadow {
  box-shadow: 2px 2px 5px 4px #b0c2cd;
}
button {
  outline-width: 0px;
  border: none;
}
.pricing__header {
  text-align: center;
}
.pricing__header h1 {
  font-size: 40px;
  margin: 10px;
  font-weight: bolder;
}
.pricing__header__button > div {
  display: inline-block;
  padding: 20px 40px;
  background: white;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid #7f7f7f;
  cursor: pointer;
}
.pricing__header__button > div:nth-child(1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pricing__header__button > div:nth-child(3) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pricing__header__button > div:nth-child(2) {
  border-left: none;
  border-right: none;
}
.selected__price__button {
  background: #00b2d9 !important;
  color: white !important;
  border-color: transparent !important;
}

.pricing__card__container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin: 50px 0px;
}

.courses .pricing__card__container {
  align-items: flex-start;

  margin-top: 20px;
}
.pricing__card__container > div {
  width: 30%;
  min-width: 220px;
  flex: 1 1 auto;
  margin: 0 10px;
}
.pricing__card__main span {
  font-weight: 600;
}
.pricing__card {
  text-align: center;
  margin-top: -100px;
}
.pricing__card__image {
  width: 180px;
  position: relative;
  bottom: -70px;
  margin-top: -80px;
}

.pricing__card__content {
  border-radius: 5px;
  margin: 0px 10px;
  background: #f2f2f2;
  border: 3px solid transparent;
  border-radius: 10px;
}
.pricing__card__main {
  padding: 15px;
}

.pricing__level {
  padding-top: 50px;
  padding-bottom: 10px;
  color: white;
  border-radius: 10px;
}
.pricing__level > h1 {
  margin: 0px;
}

.pricing__card__box {
  text-align: center;
  font-size: 12px;
  background: white;
  border-radius: 10px;
  padding: 5px 2px;
  margin: 25px auto;
  /* font-weight: 600; */
}
.pricing__card__box > h1 {
  font-size: 15px;
  font-weight: 650;
}
.pricing__card__box > img {
  width: 60px;
  margin: 0px 15px;
}
.pricing__card__toggler {
  font-size: 12px;
  cursor: pointer;
  display: none;
}
.pricing__card__achievement {
  width: 60px !important;
}

.pricing__card__price {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
.pricing__card__price > span:nth-child(2) {
  margin: 0px 5px;
}
.discount__price {
  font-size: 20px;
  font-weight: bold;
}
.actual__price {
  font-size: 12px;
  font-weight: bold;
  text-decoration: line-through;
}
.discount__left__arrow {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-right: 6px solid #ffce00;
  border-bottom: 3px solid transparent;
}
.discount__badge {
  background: #ffce00;
  font-size: 12px;
  padding: 1px;
  width: 45px;
  border-radius: 5px;
}
.pricing__session__price {
  font-size: 10px;
  font-weight: bold;
}
.download__currciculum {
  background: white;
  text-decoration: none;
  color: black !important;
  font-size: 15px;
  padding: 10px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.download__currciculum > img {
  width: 20px;
  margin-right: 20px;
}
.buynow {
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0px;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  border: 2px solid black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.homapage__beginner__bagde,
.homapage__advance__bagde {
  color: white;
  font-weight: bold;
  padding: 0px 2px;
  margin: auto 2px;
  border-radius: 5px;
}
.homapage__beginner__bagde {
  background: #00b2d9;
}
.homapage__advance__bagde {
  background: #f34444;
}

.box01 {
  min-height: 170px;
}
.box02 {
  min-height: 100px;
}
.box03 {
  min-height: 100px;
}
.box04 {
  min-height: 130px;
}
.box05 {
  min-height: 170px;
}

.box11 {
  min-height: 150px;
}
.box12 {
  min-height: 150px;
}
.box13 {
  min-height: 120px;
}
.box14 {
  min-height: 170px;
}
.box15 {
  min-height: 170px;
}

.box21 {
  min-height: 150px;
}
.box22 {
  min-height: 130px;
}
.box23 {
  min-height: 100px;
}
.box24 {
  min-height: 170px;
}
.box25 {
  min-height: 170px;
}

.pricing__fotter {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border: 2px solid #00b4db;
  border-radius: 10px;
  margin: auto 12%;
  padding: 20px;
  margin-bottom: 80px;
}
.pricing__fotter > img {
  width: 50px;
}

@media screen and (max-width: 1250px) {
  .pricing__card__container > div {
    width: 80%;
  }
}
@media screen and (max-width: 990px) {
  .curriculum__pricing {
    margin-top: 100px;
  }
  .pricing__header > h1 {
    font-size: 30px;
  }
  .pricing__header__button > div {
    padding: 10px;
    font-size: 12px;
    width: 25%;
  }

  .pricing__card__toggler {
    display: block;
  }
  .pricing__fotter {
    display: none;
  }

  .box01 {
    min-height: 0px;
  }
  .box02 {
    min-height: 0px;
  }
  .box03 {
    min-height: 0px;
  }
  .box04 {
    min-height: 0px;
  }
  .box05 {
    min-height: 0px;
  }

  .box11 {
    min-height: 0px;
  }
  .box12 {
    min-height: 0px;
  }
  .box13 {
    min-height: 0px;
  }
  .box14 {
    min-height: 0px;
  }
  .box15 {
    min-height: 0px;
  }

  .box21 {
    min-height: 0px;
  }
  .box22 {
    min-height: 0px;
  }
  .box23 {
    min-height: 0px;
  }
  .box24 {
    min-height: 0px;
  }
  .box25 {
    min-height: 0px;
  }
}
