.referral {
  display: flex;
  flex-direction: column;
}

.referral_info {
  width: 100%;
  margin: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid var(--theme_color);
  border-radius: 20px;
  padding: 20px;
}

.referral_title {
  color: var(--theme_color);
  font-weight: 900;
  font-size: clamp(1.8rem, 2.6vw, 3rem);
}

.referral_exp {
  color: var(--theme_color);
  font-size: clamp(0.9rem, 1.5vw, 1.5rem);
}

.social_icon {
  margin-top: 20px;
}

.social_icon img {
  width: 42px;
  height: auto;
  margin: 0 10px 20px 0;
}
.referral_link_conrainer .link {
  border: 1px solid var(--theme_color);
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  padding: 2px 20px;
  cursor: pointer;
  overflow: hidden;
  height: 33px;
  font-size: 0.8rem;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.referral_link_conrainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50vw;
}
.referral_link_conrainer div {
  padding: 3px;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
}

@media all and (max-width: 500px) {
  .referral_link_conrainer {
    width: 100%;
  }
  .referral_link_conrainer .link{
    justify-content: flex-start;
    padding: 2px 10px;
  }
  .referral_link_conrainer div {
    padding: 4px;
  }
}

.copy_btn {
  background-color: var(--theme_color);
}
.copy_btn svg {
  margin: 0 8px;
}
.referral_table {
  width: 600px;
}
.referral_table td,
.referral_table th {
  border: 2px solid #000;
}
.f-m {
  margin-left: 2px;
}
.tnc {
  text-align: center;
}
.info_text {
  margin-top: 50px;
  font-weight: 900;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  vertical-align: middle;
}
ol li {
  font-weight: bold;
}
li > span {
  font-weight: normal;
}
.info_text ol {
  margin: 0 20px;
  font-weight: 500;
  font-size: clamp(0.7rem, 1.2vw, 1.2rem);
}
.sh_bg {
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 5px 1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
@media screen and (max-width: 780px) {
  .referral {
    padding: 0 50px;
  }
}

@media screen and (max-width: 500px) {
  .referral {
    padding: 0 0;
  }
  .referral_table {
    width: 300px;
  }
}
